import Head from 'next/head';

const FourOhFour = () => {
	return (
		<>
			<Head>
				<title>404 | Page Not Found</title>
			</Head>
			<div className="w-full flex flex-col justify-center items-center gap-10 my-10">
				<img className="w-1/2" src="/images/404.svg" alt="Page Not Found" />
				<h1 className="text-xl font-bold md:text-4xl">Error 404 | <span className="font-light">you landed on nowhere.</span></h1>
			</div>
		</>
	);
};

export default FourOhFour;
